/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import {
  GlobeAltIcon,
  LightningBoltIcon,
  ScaleIcon,
    UsersIcon,
    SwitchHorizontalIcon

} from "@heroicons/react/outline";

const features = [
  {
    name: "Build Transferable Solutions",
    description:
      "We help build all the web application solutions and mobile application solutions for our clients, and transfer the full ownership of the solution whenever the client is ready.",
    icon: SwitchHorizontalIcon,
  },
  {
    name: "Remote Resource Augmentation",
    description:
      "When the clients are wishing to add more remote team members to their existing team or want to create a new team for their solution altogether, then we do provide them the resources who work in the different time zones.",
    icon: UsersIcon,
  },
  {
    name: "Managed Solution Delivery",
    description:
      "Sometimes clients want to have a minimal involvement in the solution but need timely deliveries that fall under this model. We take full ownership of the entire solution and will work according to the discussed timelines.",
    icon: LightningBoltIcon,
  },
];

export default function OtherService() {
  return (
    <div className="py-12">
      <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="max-w-4xl mb-16 mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            What we offer
          </h2>

        </div>
        <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
          {features.map((feature) => (
            <div key={feature.name}>
              <dt>
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                  <feature.icon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="mt-5 text-lg leading-6 font-medium text-gray-900">
                  {feature.name}
                </p>
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                {feature.description}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
